
import { defineComponent } from 'vue';

// Composition
import { loginComposition } from '../services/login.composition';

export default defineComponent({
    setup () {
		return { ...loginComposition() }
    },
})
