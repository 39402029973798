/**
 * API helper file
 * 
 * Author: Sahil David
 */

import { getTokens } from '../auth.tokens';

/**
 * Method to apply api headers to axios call 
 */
export function apiHeader (token: string) {
	const config: any = {
		headers: {
			'Content-Type': 'application/json',
		}
	};

	/**
	 * Adding authorizations header
	 */
	const { accessToken, refreshToken } = getTokens();
	if (token === 'access' && accessToken) {
		config.headers.authorization = `Bearer ${accessToken}`;
	}

	if (token === 'refresh' && refreshToken) {
		config.headers.authorization = `Refresh ${refreshToken}`;
	}

	return Object.assign({}, config);
}

/**
 * Method to handle api responses
 */
export function apiResponseHandler (response: any) {
	const { data, status } = response;

	/**
	 * Unauthorized requests, user logout
	 */
	if (status === 401) {
		// eslint-disable-next-line no-console
		console.error('[huumun datota]: Unauthorized API Request');
	}

	return Object.assign({}, data, { status: response.status });
}

/**
 * Helper function to get dynamic
 * @param url - url to be converted.
 * @param params - Defaults to `{}`. Object containing parameters to be replaced,
 * e.g. { ':param': value }
 * @param queries - Defaults to `{}`. Object containing queries to add.
 * e.g. { query: value } will be added as `?query=value`
 */
export function apiDynamicURL (endpoint: string, params: any = {}, queries: any = {}) {
	let _url = endpoint;
	const _parameters = Object.keys(params);
	const _queries = Object.keys(queries);

	/**
	 * Replace dynamic params.
	 */
	_parameters.forEach((parameter) => {
		_url = _url.replace(parameter, params[parameter]);
	});

	/**
	 * Add queries.
	 */
	_queries.forEach((query, index) => {
		_url += index < 1 ? '?' : '&';
		_url += `${query}=${queries[query]}`;
	});

	return _url;
}
