/**
 * Login composition api 
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { $post } from '@/core/services/api';
import { redirectUrl } from '@/core/services/routing';
import { saveTokens } from '@/core/services/auth.tokens';

export function loginComposition () {
	const store = useStore();
	const router = useRouter();

	// Data members
	const username = ref<string>('');
	const password = ref<string>('');
	const error = ref<string>('');

	// Validate user login 
	async function validateUserLogins () {
		// Client side validation
		if (!(username.value && password.value)) {
			error.value = 'Please enter your login details';
			return;
		}
		
		// API check
		const response = await $post('login', { 
			username: username.value,
			password: password.value,
		});

		// Error response
		if (response.error) {
			error.value = 'Invalid login details, try again!';
			return;
		}

		// Success response
		if (response.success) {
			const { accessToken, refreshToken } = response;

			store.dispatch('axn_updateLoggedIn', true);
			saveTokens(accessToken, refreshToken);
			
			router.push({ 
				path: redirectUrl(),
			});
		}
	}

	return { username, password, error, validateUserLogins };
}