/**
 * Service for API methods
 * 
 * Author: Sahil David
 */
import axios from 'axios';

import { apiHeader, apiResponseHandler, apiDynamicURL } from './api.helpers';
import endpoints from '@/config/api.endpoints';

/**
 * API POST Methods
 */
export async function $post (service: string, body = {}, params = {}, queries = {}, token = 'access') {
	const url = endpoints[service];
	if (!url) {
		console.warn(`[API] No endpoint found for service: ${service}`);
		return;
	}

	try {
		const response = await axios.post(apiDynamicURL(url, params, queries), body, apiHeader(token));
		return apiResponseHandler(response);
	} catch (err: any) {
		const { response } = err;
		return apiResponseHandler(response);
	}
}

/**
 * API GET Methods
 */
 export async function $get (service: string, params = {}, queries = {}, token = 'access') {
	const url = endpoints[service];
	if (!url) {
		console.warn(`[API] No endpoint found for service: ${service}`);
		return;
	}

	try {
		const response = await axios.get(apiDynamicURL(url, params, queries), apiHeader(token));
		return apiResponseHandler(response);
	} catch (err: any) {
		const { response } = err;
		return apiResponseHandler(response);
	}
}
