import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b1bd0f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hd-login-body" }
const _hoisted_2 = { class: "hd-login-body__form" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "hd-login-body__form-footer" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field_module = _resolveComponent("form-field-module")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "hd-login-body__title",
        innerHTML: _ctx.$t('login.title')
      }, null, 8, _hoisted_3),
      _createVNode(_component_form_field_module, {
        icon: "user",
        placeholder: "e.g.: huumun",
        callback: (val) => { _ctx.username = val }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('login.form.username')), 1)
        ]),
        _: 1
      }, 8, ["callback"]),
      _createVNode(_component_form_field_module, {
        icon: "password",
        type: "password",
        placeholder: "******",
        callback: (val) => { _ctx.password = val }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('login.form.password')), 1)
        ]),
        _: 1
      }, 8, ["callback"]),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "hd-login-body__form-error",
            innerHTML: _ctx.error
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "hd-login-body__reset-password",
          innerHTML: _ctx.$t('login.form.reset')
        }, null, 8, _hoisted_6),
        _createVNode(_component_base_button, { callback: _ctx.validateUserLogins }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('login.form.submit')), 1)
          ]),
          _: 1
        }, 8, ["callback"])
      ])
    ])
  ]))
}