/**
 * API Endpoints - URL for Web Services
 * 
 * To be added with application development
 */

const endpoints: any = {
	template: '/api/v1//template',
	login: 'api/v1/auth/login'
};

export default endpoints;
