
import { defineComponent } from 'vue';

import LoginLogo from '../components/login-logo.vue';
import LoginBody from '../components/login-body.vue';

export default defineComponent({
	components: {
		LoginLogo,
		LoginBody,
	},
})
